import { Injectable } from '@angular/core';

const testimonials = [
  {
    customerImage: '',
    name: 'Nakshatra Vasu',
    rating: 5,
    review: `Really good experience. The scooty was almost new and did not give any issues throughout the month.`,
    isPopular: true
  },
  {
    customerImage: '',
    name: 'Sudesh Mahato',
    rating: 5,
    review: `Outstanding service its give the flexibility of taking more than 24 hrs with valuable price. My friend got stuck with different rental web site but selfpin helps a lot.`,
    isPopular: true
  },
  {
    customerImage: '',
    name: 'Saravanan M',
    rating: 5,
    review: `It was a smooth experience with selfspin. I used their Activa for 2 days. It was almost new vehicle and overall had a satisfactory experience. Will definitely recommend to others. I interacted with Salman, he was very courteous and helpful.`,
    isPopular: true
  },
  {
    customerImage: '',
    name: 'Sourabh Todkar',
    rating: 5,
    review: `NICE SERVICE ALSO CAR GOOD CONDITION all car and THANKS FOR SUPPORT TODAY BHIMASHANKAR TOUR It was an amazing experience with self spin today. We urgently needed a car for our trip and they helped us along the way heavily. Our amazing trip wouldn't be possible without them. Loved the staff and the car was excellent. Will surely book a car again sometime very soon.`,
    isPopular: true
  },
  {
    customerImage: '',
    name: 'Punya Patil',
    rating: 5,
    review: `It was an amazing experience with selfspin today. We urgently needed a car for our trip and they helped us along the way heavily. Our amazing trip wouldn't be possible without them. Loved the staff and the car was excellent. Will surely book a car again sometime very soon.`,
    isPopular: true
  },
  {
    customerImage: '',
    name: 'Pankaj Dwivedi',
    rating: 5,
    review: `If you want to find a good scooty on rent with no hidden charges and best prices, choose this. Took it for 1 month and had to renew it for another because of the services and experience with Self spin (Indiranagar).`,
    isPopular: false
  },
  {
    customerImage: '',
    name: 'Nakul Deokar',
    rating: 5,
    review: `I had a good experience with Selfspin in Pune, but recently, I rented a vehicle from them in Goa. Their service is always top-notch, and renting from Selfspin is always a pleasant experience.`,
    isPopular: false
  },
  {
    customerImage: '',
    name: 'Vishnuraj K R',
    rating: 5,
    review: `Great service and nicely maintained vehicles with comparatively lowest tariff than other rentals in the city. Keep up the good work.`,
    isPopular: false
  },
  {
    customerImage: '',
    name: 'NAGARAJU H.N.',
    rating: 5,
    review: `Highly recommended bike rental... I took Activa had good experience, scooter condition was very good.Bikes are new and very well maintained.`,
    isPopular: false
  },
  {
    customerImage: '',
    name: 'Prabhav Goel',
    rating: 5,
    review: `Great service and great prices. Booked thrice for 12hours each for whole week, and the process was seemless. Flexible with extending hours as well. Would recommend 100 percent.`,
    isPopular: false
  },
  {
    customerImage: '',
    name: 'Ijar Ansari',
    rating: 5,
    review: `The app is easy to use, has alot of car options even for a last minute booking. The service is fast and seem less and they deliver the car to you as it is supposed to be. Cheaper than other service providers and better options and cars than other service providers. I have used zoomcar and suffered the service. I'm glad I found SELFSPIN. Thanks guys. I'll be a regular customer.`,
    isPopular: false
  },
  {
    customerImage: '',
    name: 'Mahesh Reddy',
    rating: 5,
    review: `Zabi was very helpful and staff was gave good vehicle bike was in good condition. Location is very easy to find. Transfer also very easy thank you selfspin.`,
    isPopular: false
  }
];

@Injectable({
  providedIn: 'root'
})
export class TestimonialsService {

  constructor() { }

  // Get testimonials
  getTestimonials() {
    return testimonials;
  }
}
